import { v4 } from 'uuid'

import { APP_CONFIG } from 'src/config.app'
import { ItemGroupDTO } from 'src/models/dto/activities/ItemGroupDTO'
import { ActivityType } from 'src/models/dto/ActivityDTO'
import { ApprovalDTO } from 'src/models/dto/approval/ApprovalDTO'
import { CommentDTO, LocationId } from 'src/models/dto/approval/CommentDTO'
import {
    CreateModuleReviewInput,
    ModuleGroupReviewRequest,
    ReviewDTO,
} from 'src/models/dto/approval/ReviewDTO'
import { GetRevisionInput } from 'src/models/dto/approval/RevisionDTO'
import { InitiateUATApprovalProcessInput, UATReviewDTO } from 'src/models/dto/approval/UATReviewDTO'
import { ApprovalStatus } from 'src/models/dto/ApprovalStatus'
import { FreeTextDisplayType } from 'src/models/dto/items/FreeTextItemDTO'
import { ItemType } from 'src/models/dto/items/ItemDTO'
import { Locale } from 'src/models/dto/Locale'
import { ModuleDTO } from 'src/models/dto/ModuleDTO'
import { ModuleStatus } from 'src/models/dto/ModuleStatus'
import { ModuleReviewStatusDTO } from 'src/models/dto/ModuleVersionDTO'
import { ADAxios, ApiActionNames, ApprovalServiceApiActions } from 'src/services/AxiosInterceptor'
import { handleAxiosError } from 'src/services/backend/ModuleService'

export const placeholderFreeTextItem = () => {
    const newId = v4()
    return {
        id: newId,
        name: newId,
        freeTextDisplayType: FreeTextDisplayType.SingleLine,
        characterLimit: 50,
        optional: false,
        preserveOrder: false,
        label: 'label1',
        locale: Locale.en_US,
        itemType: ItemType.FreeText,
        ppt: ItemType.FreeText,
        questionI18N: {
            [Locale.en_US]: 'Question EN',
            [Locale.es_ES]: 'Question ES',
        },
    }
}

export const placeholderModuleDTO = () => {
    return {
        name: 'TestModule',
        id: 'moduleId1',
        version: 'moduleVersionId100',
        availableLocales: [Locale.en_US, Locale.es_ES],
        autoProgressionOn: false,
        status: '',
        compositeScores: [],
        workflow: [
            {
                moduleChild: {
                    id: v4(),
                    name: 'C1',
                    items: [placeholderFreeTextItem(), placeholderFreeTextItem()],
                    randomizationEnabled: false,
                    description: '',
                    type: ActivityType.LaunchItemGroup,
                    ppt: ActivityType.LaunchItemGroup,
                } as ItemGroupDTO,
            },
            {
                moduleChild: {
                    id: v4(),
                    name: 'C2',
                    items: [placeholderFreeTextItem(), placeholderFreeTextItem()],
                    randomizationEnabled: false,
                    description: '',
                    type: ActivityType.LaunchItemGroup,
                    ppt: ActivityType.LaunchItemGroup,
                } as ItemGroupDTO,
            },
        ],
    } as ModuleDTO
}

export interface PutApprovalInput {
    reviewId: string
    revisionNumber: string
    reviewer: string
    approvalStatus: ApprovalStatus
}

export interface CreateCommentInput {
    reviewId: string
    revisionNumber: string
    author: string
    message: string
    locationId: LocationId
}

export function placeholderReviewStatus(
    moduleVersionId: string | undefined,
    reviewId: string | undefined,
    revisionNumber = 1
) {
    return {
        versionId: moduleVersionId,
        status: ModuleStatus.DRAFT_VALIDATED,
        reviewId,
        revisionNumber,
        createdBy: 'user0',
        createdAt: new Date().toString(),
        modifiedBy: 'user3',
        modifiedAt: new Date().toString(),
    } as ModuleReviewStatusDTO
}

export class ApprovalService {
    private static readonly approvalUrl = APP_CONFIG.approvalUrl

    private static revisionUrl(reviewId: string, revisionNumber: string, end = '') {
        return `${this.approvalUrl}/reviews/${reviewId}/revisions/${revisionNumber}${
            end ? '/' + end : ''
        }`
    }

    static async initiateApprovalProcess(
        input: CreateModuleReviewInput,
        abortController?: AbortController
    ): Promise<void> {
        try {
            const url = `${APP_CONFIG.backendAPIBaseUrl}/modules-versions/${input.moduleVersionId}/reviews`
            await ADAxios.post(url, input, {
                apiActionName: ApiActionNames.InitiateApprovalProcess,
                signal: abortController?.signal,
            })
        } catch (e: unknown) {
            handleAxiosError(e)
        }
    }
    static async initiateModuleGroupReviewProcess(
        input: ModuleGroupReviewRequest,
        abortController?: AbortController
    ): Promise<void> {
        try {
            const url = `${APP_CONFIG.backendAPIBaseUrl}/module-groups-versions/${input.versionId}/reviews`
            await ADAxios.post(url, input, {
                apiActionName: ApiActionNames.InitiateApprovalProcess,
                signal: abortController?.signal,
            })
        } catch (e: unknown) {
            handleAxiosError(e)
        }
    }

    static async initiateUATApprovalProcess(
        input: InitiateUATApprovalProcessInput,
        abortController?: AbortController
    ): Promise<UATReviewDTO> {
        try {
            const url = `${APP_CONFIG.backendAPIBaseUrl}/modules-versions/${input.versionId}/uat-review`
            const res: { data: { uatReview: UATReviewDTO } } = await ADAxios.post(url, input, {
                apiActionName: ApiActionNames.InitiateUATApprovalProcess,
                signal: abortController?.signal,
            })

            return res.data?.uatReview
        } catch (e: unknown) {
            handleAxiosError(e)
        }
    }

    static async createComment(
        input: CreateCommentInput,
        abortController?: AbortController
    ): Promise<void> {
        const { reviewId, revisionNumber } = input
        try {
            await ADAxios.post(this.revisionUrl(reviewId, revisionNumber, 'comments'), input, {
                apiActionName: ApprovalServiceApiActions.CreateComment,
                signal: abortController?.signal,
            })
        } catch (e: unknown) {
            handleAxiosError(e)
        }
    }

    static async listAllCommentsForReview(
        review: ReviewDTO,
        abortController?: AbortController
    ): Promise<CommentDTO[] | null> {
        const reviewId = review.reviewId
        const res: CommentDTO[] = []
        for (const comments of await Promise.all(
            review.revisionList.map((r) =>
                this.listComment({ reviewId, revisionNumber: r.revisionNumber }, abortController)
            )
        )) {
            if (comments) {
                comments.forEach((c) => res.push(c))
            }
        }
        return res
    }

    static async listComment(
        { reviewId, revisionNumber }: GetRevisionInput,
        abortController?: AbortController
    ): Promise<CommentDTO[] | null> {
        try {
            const { data }: { data: { comments?: CommentDTO[] } } = await ADAxios.get(
                this.revisionUrl(reviewId, revisionNumber, 'comments'),
                {
                    apiActionName: ApprovalServiceApiActions.ListComment,
                    signal: abortController?.signal,
                }
            )
            return data.comments || null
        } catch (e: unknown) {
            handleAxiosError(e)
        }
    }

    static async listApproval(
        { reviewId, revisionNumber }: { reviewId: string; revisionNumber: string },
        abortController?: AbortController
    ): Promise<ApprovalDTO[]> {
        try {
            const { data }: { data: { approvals: ApprovalDTO[] } } = await ADAxios.get(
                this.revisionUrl(reviewId, revisionNumber, 'approvals'),
                {
                    apiActionName: ApprovalServiceApiActions.ListApproval,
                    signal: abortController?.signal,
                }
            )
            return data.approvals
        } catch (e: unknown) {
            handleAxiosError(e)
        }
    }

    static async putApproval(input: PutApprovalInput, abortController?: AbortController) {
        try {
            const { reviewId, revisionNumber } = input
            const { data }: { data: { approval: ApprovalDTO } } = await ADAxios.put(
                this.revisionUrl(reviewId, revisionNumber, 'approvals'),
                input,
                {
                    apiActionName: ApprovalServiceApiActions.PutApproval,
                    signal: abortController?.signal,
                }
            )
            return data.approval
        } catch (e: unknown) {
            handleAxiosError(e)
        }
    }

    static async getReview(
        reviewId: string,
        abortController?: AbortController
    ): Promise<ReviewDTO | null> {
        try {
            const { data }: { data: { review?: ReviewDTO } } = await ADAxios.get(
                `${this.approvalUrl}/reviews/${reviewId}`,
                {
                    apiActionName: ApprovalServiceApiActions.GetReview,
                    signal: abortController?.signal,
                }
            )
            return data.review ?? null
        } catch (e: unknown) {
            handleAxiosError(e)
        }
    }
}
