import { AxiosError, AxiosResponse } from 'axios'

import { APP_CONFIG } from 'src/config.app'
import { BucketsAndCupsGroupGroupEntity } from 'src/models/dto/activities/BucketsAndCupsGroupDTO'
import {
    defaultItemGroupDTO,
    ItemGroupDTO,
    ItemGroupEntity,
} from 'src/models/dto/activities/ItemGroupDTO'
import { MUPPExamDTO, MUPPExamEntity } from 'src/models/dto/activities/MUPPExamDTO'
import {
    RandomSelectionDTO,
    RandomSelectionGroupEntity,
} from 'src/models/dto/activities/RandomSelectionGroupDTO'
import { ActivityDTO, ActivityType, WorkflowItem } from 'src/models/dto/ActivityDTO'
import { ErrorResponseThrowable } from 'src/models/dto/ErrorResponse'
import { InstructionalContentEntity } from 'src/models/dto/InstructionalContentDTO'
import { InstructionalContentDTO, ItemDTO, ItemType } from 'src/models/dto/items/ItemDTO'
import { LikertItemDTO } from 'src/models/dto/items/LikertItemDTO'
import { MultipleChoiceItemDTO } from 'src/models/dto/items/MultipleChoiceItemDTO'
import {
    RankingCellSubType,
    RankingCellType,
    RankingResponseTableItemDTO,
} from 'src/models/dto/items/RankingItemDTO'
import { TableItemDTO } from 'src/models/dto/items/TableItemDTO'
import { LocalizedAttribute, LocalizeDefault } from 'src/models/dto/Locale'
import {
    DeployModuleVersionDTO,
    DeployModuleVersionResponse,
    ModuleDeploymentTargetStage,
} from 'src/models/dto/ModuleDeployment'
import { ModuleDTO, ModuleEntity } from 'src/models/dto/ModuleDTO'
import { ModuleStatus } from 'src/models/dto/ModuleStatus'
import { ModuleHistoryDTO, ModuleVersionDTO } from 'src/models/dto/ModuleVersionDTO'
import { SaveModuleVersionResponse } from 'src/models/dto/SaveModuleVersionResponse'
import { MyModulesResponse } from 'src/models/SearchResultResponse'
import { ADAxios, ApiActionNames } from 'src/services/AxiosInterceptor'
import { BucketsAndCupsGroupHandler } from 'src/services/EntityServices/ActivityUpdateHandlers/BucketsAndCupsGroupHandler'
import { ItemGroupHandler } from 'src/services/EntityServices/ActivityUpdateHandlers/ItemGroupHandler'
import { MUPPExamHandler } from 'src/services/EntityServices/ActivityUpdateHandlers/MUPPExamHandler'
import { RandomSelectionGroupHandler } from 'src/services/EntityServices/ActivityUpdateHandlers/RandomSelectionGroupHandler'
import { ContextBoxEntityService } from 'src/services/EntityServices/ContextBoxEntityService'
import { InstructionalContentEntityService } from 'src/services/EntityServices/InstructionalContentEntityService'
import { LikertGroupHandler } from 'src/services/EntityServices/ItemUpdateHandlers/LikertGroupHandler'
import { TableOperations } from 'src/services/EntityServices/ItemUpdateHandlers/TableOperations'
import { STORE_ACTION } from 'src/services/Store'
import { ActivityEntityService } from '../EntityServices/ActivityEntityService'
import { RankingResponseTableHandler } from '../EntityServices/ItemUpdateHandlers/RankingResponseTableHandler'
import { ItemEntityService } from './../EntityServices/ItemEntityService'
import { ModuleEntityService } from './../EntityServices/ModuleEntityService'

interface ModuleVersionDTOResponse extends Omit<ModuleVersionDTO, 'updatedAt' | 'content'> {
    updatedAt: number | string
    content: string
}

interface ModuleHistoryDTOResponse {
    history: ModuleHistoryDTO[]
}

const MapDeploymentTargetStageToApiActionName: Record<ModuleDeploymentTargetStage, ApiActionNames> =
    {
        PREVIEW: ApiActionNames.DeployModuleVersionPreview,
        UAT: ApiActionNames.DeployModuleVersionUAT,
        PRODUCTION: ApiActionNames.DeployModuleVersionProduction,
    }

export function handleAxiosError(e: unknown): never {
    const axiosError = e as AxiosError | undefined
    if (axiosError?.response?.data) {
        throw new ErrorResponseThrowable(axiosError.response.data)
    } else {
        throw e
    }
}

export enum DownloadTemplatesType {
    JSON = 'json',
    CSV = 'csv',
}
export class ModuleService {
    public static async loadModuleVersionDTO(
        moduleVersionId: string,
        excludeContent?: boolean,
        abortController?: AbortController
    ): Promise<ModuleVersionDTO> {
        try {
            const { data }: { data: ModuleVersionDTOResponse } = await ADAxios.get(
                `${APP_CONFIG.backendAPIBaseUrl}/modules-versions/${moduleVersionId}` +
                    (excludeContent ? '?excludeContent=true' : ''),
                {
                    apiActionName: ApiActionNames.GetModuleVersion,
                    signal: abortController?.signal,
                }
            )

            return {
                ...data,
                updatedAt: new Date(data.updatedAt),
                content: JSON.parse(data.content) as ModuleDTO,
            } as ModuleVersionDTO
        } catch (e: unknown) {
            handleAxiosError(e)
        }
    }

    public static async saveModuleVersion(
        moduleDTO: ModuleDTO,
        saveComments: string,
        lastKnownSavedTimeToken: number,
        abortController?: AbortController,
        migrateMedia?: boolean
    ): Promise<SaveModuleVersionResponse> {
        try {
            const {
                data,
            }: {
                data: Omit<SaveModuleVersionResponse, 'updatedAt'> & {
                    updatedAt: number | string
                }
            } = await ADAxios.post(
                `${APP_CONFIG.backendAPIBaseUrl}/modules-versions`,
                {
                    versionId: moduleDTO.version,
                    lastKnownSavedTimeToken,
                    saveComments,
                    content: JSON.stringify(moduleDTO),
                    migrateToMediaManager: migrateMedia,
                },
                {
                    apiActionName: ApiActionNames.SaveModuleVersion,
                    signal: abortController?.signal,
                }
            )
            return {
                ...data,
                updatedAt: new Date(data.updatedAt),
            } as unknown as SaveModuleVersionResponse
        } catch (e: unknown) {
            handleAxiosError(e)
        }
    }

    public static async requestTranslations(
        versionId: string,
        requester: string,
        notificationEmail: string,
        locales: string[] = []
    ) {
        try {
            const {
                data,
            }: {
                data: { versionId: string }
            } = await ADAxios.post(
                `${APP_CONFIG.backendAPIBaseUrl}/modules-versions/${versionId}/translate`,
                {
                    versionId: versionId,
                    requester: requester,
                    notificationEmail: notificationEmail,
                    locales: locales.map((l) => l.replace('_', '-')),
                },
                {
                    apiActionName: ApiActionNames.RequestTranslation,
                }
            )
            return data
        } catch (e: unknown) {
            handleAxiosError(e)
        }
    }

    public static async getMyModules(userId: string): Promise<MyModulesResponse> {
        try {
            const getMyModulesURL = `${APP_CONFIG.backendAPIBaseUrl}/users/${userId}/modules-versions`

            const { data }: { data: MyModulesResponse } = await ADAxios.get(getMyModulesURL, {
                apiActionName: ApiActionNames.GetMyModules,
            })
            return data
        } catch (e: unknown) {
            handleAxiosError(e)
        }
    }

    public static createBlankDto() {
        const entity = ModuleEntityService.create('')
        const blankPage = ActivityEntityService.create(ActivityType.LaunchItemGroup)
        ModuleEntityService.addActivity(entity.version, blankPage.id)
        return this.serializeModuleDTO(entity.version)
    }

    private static wrapLaunchItemActivity(activityDto: ActivityDTO) {
        return {
            ...activityDto,
            moduleChild: {
                ...defaultItemGroupDTO(),
                items: [activityDto.moduleChild as unknown as ItemDTO],
            },
        }
    }

    /**
     * Populates a set of modules into the various stores
     * This will call the other populate methods if there is data to populate
     * @param dtos array of ModuleDTOs
     *
     * outcome: Stores are updated with entities that reflect the DTOs or first error is thrown
     */
    public static populateModules(dtos: ModuleDTO[]) {
        dtos.forEach((dto) => {
            this.populateModule(dto)
        })
    }

    public static populateModule(dto: ModuleDTO) {
        const { entity, activities, instructionalContent } = this.setUpEntity(dto)
        ModuleEntityService.has(entity.version)
            ? ModuleEntityService.update(entity)
            : ModuleEntityService.insert(entity)

        this.populateActivities(activities)
        if (instructionalContent) this.populateInstructionalContent(instructionalContent)
    }

    public static updateModule(dto: ModuleDTO) {
        const { entity, activities, instructionalContent } = this.setUpEntity(dto)
        ModuleEntityService.update(entity)

        this.populateActivities(activities, STORE_ACTION.REQUEST_UPDATE)
        if (instructionalContent)
            this.populateInstructionalContent(instructionalContent, STORE_ACTION.REQUEST_UPDATE)
    }

    private static setUpEntity(dto: ModuleDTO) {
        const { workflow, instructionalContent, ...rest } = dto
        let completionCodeItemId = ''
        let completionCodeItemGroupId = ''
        const activities: ActivityDTO[] = workflow.map((activity, idx) => {
            if (activity.moduleChild.type === ActivityType.LaunchItem) {
                return this.wrapLaunchItemActivity(activity)
            }
            // checks the last page and if the page only contains a completion code
            // we save the page id and the completion code item id
            if (idx === workflow.length - 1) {
                const itemGroup = activity
                if (
                    itemGroup.moduleChild.items &&
                    itemGroup.moduleChild.items.length == 1 &&
                    itemGroup.moduleChild.items[0].itemType === ItemType.CompletionCode
                ) {
                    completionCodeItemId = itemGroup.moduleChild.items[0].id
                    completionCodeItemGroupId = itemGroup.moduleChild.id
                }
            }
            return activity
        })

        let activityIds = activities.map((workflowDTO) => workflowDTO.moduleChild.id)
        if (completionCodeItemGroupId)
            activityIds = activityIds.filter(
                (activityId) => activityId !== completionCodeItemGroupId
            )

        let entity: ModuleEntity = {
            ...rest,
            workflowIds: activityIds,
            mturkPaymentCodeItemId: completionCodeItemId,
        }

        if (instructionalContent) {
            entity = {
                ...entity,
                instructionalContentId: instructionalContent.id,
            }
        }

        return {
            entity: entity,
            activities: activities,
            instructionalContent: instructionalContent,
        }
    }

    /**
     *
     * @param dto InstructionalContentDTO to populate
     *
     * outcome: Instruction Content Store is populated or first error is thrown
     */
    public static populateInstructionalContent(
        dto: InstructionalContentDTO,
        action = STORE_ACTION.REQUEST_CREATE
    ) {
        const { items: _items, ...rest } = dto
        const itemIds = dto.items.map((i) => i.id)

        const entity: InstructionalContentEntity = {
            ...rest,
            itemIds: itemIds,
        }
        InstructionalContentEntityService.preformAction(entity, action)
        this.populateItems(dto.items, action)
    }

    /**
     *
     * @param dtos set of ItemGroupDTOs to populate
     *
     * outcome: ItemGroup and Item Stores are populated or first error is thrown
     */
    public static populateActivities(dtos: ActivityDTO[], action = STORE_ACTION.REQUEST_CREATE) {
        dtos.forEach((dto: ActivityDTO) => {
            if (dto.moduleChild.type === ActivityType.LaunchItemGroup) {
                // populate item group
                const itemGroupDto = dto.moduleChild as ItemGroupDTO
                itemGroupDto.items = itemGroupDto.items.map((itemDTO) =>
                    this.convertDeprecatedItemType(itemDTO)
                )

                const { items: _items, contextBox: _removed, ...rest } = itemGroupDto
                const itemIds = itemGroupDto.items.map((i) => i.id)

                const entity: ItemGroupEntity = {
                    ...rest,
                    itemIds: itemIds,
                    ...(itemGroupDto.contextBox
                        ? {
                              contextBoxId: itemGroupDto.contextBox.id,
                          }
                        : {}),
                }

                if (itemGroupDto.contextBox) {
                    const {
                        ppt: _ignored1,
                        name: _ignored2,
                        label: _label,
                        ...others
                    } = itemGroupDto.contextBox
                    ContextBoxEntityService.insert(others)
                }

                ActivityEntityService.preformAction(entity, action)

                // populate items
                this.populateItems(itemGroupDto.items, action)
            } else if (dto.moduleChild.type === ActivityType.LaunchRandomSelection) {
                // populate random selection group
                ActivityEntityService.preformAction(
                    dto.moduleChild as RandomSelectionGroupEntity,
                    action
                )
            } else if (dto.moduleChild.type === ActivityType.LaunchCAT) {
                // populate MUPP Exam / Work Styles Exam
                ActivityEntityService.preformAction(dto.moduleChild as MUPPExamEntity, action)
            } else if (dto.moduleChild.type === ActivityType.LaunchBucketsAndCups) {
                ActivityEntityService.preformAction(
                    dto.moduleChild as BucketsAndCupsGroupGroupEntity,
                    action
                )
            }
        })
    }

    /**
     *
     * @param dtos set of ItemDTOs to populate
     *
     * outcome: Item Store is populated or first error is thrown
     */
    public static populateItems(dtos: ItemDTO[], action = STORE_ACTION.REQUEST_CREATE) {
        dtos.forEach((dto) => {
            if (dto.itemType === ItemType.Table) {
                let tableItem = dto as TableItemDTO
                tableItem = {
                    ...tableItem,
                    harveyBallLegendEnabled: TableOperations.hasAtLeastOneHarveyBallLegend(
                        tableItem.legends
                    ),
                    symbolLegendEnabled: TableOperations.hasAtLeastOneSymbolLegend(
                        tableItem.legends
                    ),
                }
                dto = tableItem
            } else if (dto.itemType === ItemType.MultipleChoice) {
                const multipleChoiceItem = dto as MultipleChoiceItemDTO

                const harveyBallLegendEnabled = TableOperations.hasAtLeastOneHarveyBallLegend(
                    multipleChoiceItem.tableResponses.legends
                )

                const symbolLegendEnabled = TableOperations.hasAtLeastOneSymbolLegend(
                    multipleChoiceItem.tableResponses.legends
                )

                multipleChoiceItem.tableResponses.symbolLegendEnabled = symbolLegendEnabled
                multipleChoiceItem.tableResponses.harveyBallLegendEnabled = harveyBallLegendEnabled
                dto = multipleChoiceItem
            }
            ItemEntityService.preformAction(dto, action)
            if (dto.itemType === ItemType.RankingResponseTable) {
                // fix
                RankingResponseTableHandler.fixSubtype(dto.id)
            }
        })
    }

    /**
     *
     * @param ItemDTO to convert if required
     *
     * outcome: Return converted/original DTO depends on the item type
     */
    private static convertDeprecatedItemType(dto: ItemDTO) {
        if (dto.itemType === ItemType.Likert) {
            return LikertGroupHandler.mapOldLikert(dto as LikertItemDTO)
        }

        return dto
    }

    /**
     *
     * @param entityId The id of the module entity we want to serialize
     * @returns a ModuleDTO
     */
    public static serializeModuleDTO(entityId: string): ModuleDTO {
        const { workflowIds, instructionalContentId, ...rest } = ModuleEntityService.get(entityId)

        const workflow = workflowIds.map((activityId) => {
            return this.serializeActivityDTO(activityId)
        })

        let entity: ModuleDTO = {
            ...rest,
            workflow,
        }

        if (instructionalContentId) {
            const instructionalContent =
                this.serializeInstructionalContentDTO(instructionalContentId)
            entity = {
                ...entity,
                instructionalContent,
            }
        }

        return entity
    }

    public static getFullModuleDTO(moduleDTO: ModuleDTO) {
        const lastItemIndex = moduleDTO.workflow.length - 1
        // if there no workflow then we already have the full module
        if (lastItemIndex < 0) return moduleDTO
        // this adds the end of module flag on the last page
        const lastWorkflowItem = moduleDTO.workflow[lastItemIndex]
        const { mturkPaymentCodeItemId } = ModuleEntityService.get(moduleDTO.version)
        moduleDTO.workflow[lastItemIndex] = {
            ...lastWorkflowItem,
            endOfModule: true,
        }

        // create a new item group activity at the end and add the completion code item there
        if (mturkPaymentCodeItemId) {
            const newActivity = ActivityEntityService.create()
            ModuleEntityService.addActivity(moduleDTO.version, newActivity.id)
            const itemGroup = ItemGroupHandler.get(newActivity.id)
            ItemGroupHandler.addItem(itemGroup.id, mturkPaymentCodeItemId)

            const serializedActivity = this.serializeActivityDTO(newActivity.id)
            moduleDTO.workflow.push(serializedActivity)
        }

        return moduleDTO
    }

    public static serializeActivityDTO(entityId: string): ActivityDTO {
        const entity = ActivityEntityService.get(entityId)

        let moduleChild: WorkflowItem

        if (entity.type === ActivityType.LaunchItemGroup) {
            // item group
            moduleChild = this.serializeItemGroupDTO(entityId)
        } else if (entity.type === ActivityType.LaunchRandomSelection) {
            // random selection group
            moduleChild = this.serializeRandomSelectionGroupDTO(entityId)
        } else if (entity.type === ActivityType.LaunchBucketsAndCups) {
            // buckets and cups group
            moduleChild = this.serializeBucketsAndCupsGroupDTO(entityId)
        } else if (entity.type === ActivityType.LaunchCAT) {
            // mupp exam / Work Styles Exam
            moduleChild = this.serializeMUPPExamDTO(entityId)
        } else {
            moduleChild = {
                id: entity.id,
                type: entity.type,
                ppt: entity.ppt,
            }
        }

        return {
            moduleChild,
            endOfModule: entity.endOfModule,
        }
    }

    public static serializeInstructionalContentDTO(entityId: string): InstructionalContentDTO {
        const { itemIds, ...entity } = InstructionalContentEntityService.get(entityId)

        const itemDTOs = itemIds.map((itemId) => {
            return this.serializeItemDTO(itemId)
        })

        return {
            ...entity,
            items: itemDTOs,
        }
    }

    public static serializeItemGroupDTO(entityId: string): ItemGroupDTO {
        const { itemIds, contextBoxId, ...entity } = ItemGroupHandler.get(entityId)

        return {
            ...entity,
            items: itemIds.map((itemId) => {
                return this.serializeItemDTO(itemId)
            }),
            ...(contextBoxId
                ? {
                      contextBox: {
                          ...ContextBoxEntityService.get(contextBoxId),
                          ppt: 'ContextBox',
                          name: ContextBoxEntityService.get(contextBoxId).id,
                          label: ContextBoxEntityService.get(contextBoxId).id,
                      },
                  }
                : {}),
        }
    }

    public static serializeRandomSelectionGroupDTO(entityId: string): RandomSelectionDTO {
        return RandomSelectionGroupHandler.get(entityId)
    }

    public static serializeMUPPExamDTO(entityId: string): MUPPExamDTO {
        return MUPPExamHandler.get(entityId)
    }

    public static serializeItemDTO(entityId: string): ItemDTO {
        const itemDTO = ItemEntityService.get(entityId)

        if (itemDTO.itemType === ItemType.RankingResponseTable) {
            const rankingItem = itemDTO as RankingResponseTableItemDTO

            const scaleMappings = new Map<RankingCellSubType, LocalizedAttribute<string>>()
            rankingItem.rankingTable.ratingScales.forEach((r) => {
                scaleMappings.set(r.subType, r.valueI18N)
            })

            const modifiedRankingItem = {
                ...rankingItem,
                rankingTable: {
                    ...rankingItem.rankingTable,
                    responseRows: rankingItem.rankingTable.responseRows.map((r) => {
                        return {
                            ...r,
                            responseCells: r.responseCells.map((cells) => {
                                return {
                                    ...cells,
                                    values: cells.values.map((v) => {
                                        const { subType: _ignored, ...rest } = v
                                        if (
                                            v.type === RankingCellType.HarveyBall ||
                                            v.type === RankingCellType.Arrow
                                        ) {
                                            rest.valueI18N =
                                                scaleMappings.get(v.subType) ?? LocalizeDefault('')
                                        }
                                        return rest
                                    }),
                                }
                            }),
                        }
                    }),
                },
            }
            return modifiedRankingItem
        } else if (itemDTO.itemType === ItemType.Table) {
            const tableItemDTO = itemDTO as TableItemDTO
            {
                const {
                    harveyBallLegendEnabled: _ignored1,
                    symbolLegendEnabled: _ignored2,
                    ...rest
                } = tableItemDTO
                return rest
            }
        }

        return itemDTO
    }

    public static async deployModuleVersion(
        moduleVersionId: string,
        deployModuleVersionDTO: DeployModuleVersionDTO
    ): Promise<DeployModuleVersionResponse> {
        try {
            const { data }: { data: DeployModuleVersionResponse } = await ADAxios.post(
                `${APP_CONFIG.backendAPIBaseUrl}/modules-versions/${moduleVersionId}/deploy`,
                deployModuleVersionDTO,
                {
                    apiActionName:
                        MapDeploymentTargetStageToApiActionName[deployModuleVersionDTO.stage],
                }
            )

            return data as unknown as DeployModuleVersionResponse
        } catch (e: unknown) {
            handleAxiosError(e)
        }
    }

    private static serializeBucketsAndCupsGroupDTO(entityId: string) {
        const { updatingCsvFile: _ignored, ...rest } = BucketsAndCupsGroupHandler.get(entityId)
        return rest
    }

    public static async downloadTemplates(
        moduleVersionId: string,
        type: DownloadTemplatesType
    ): Promise<Blob> {
        const exportURLPrefix = `${APP_CONFIG.backendAPIV1BaseUrl}/modules/export`
        const url = `${exportURLPrefix}/${moduleVersionId}/${type}`
        const resp = await ADAxios.get(url, {
            responseType: 'blob',
            apiActionName:
                type === DownloadTemplatesType.CSV
                    ? ApiActionNames.ExportModuleCSV
                    : ApiActionNames.ExportModuleJSON,
        })

        return new Blob([resp.data], { type: 'application/zip' })
    }

    public static async downloadBucketsAndCup(
        moduleVersionId: string,
        bucketsAndCupVersionId: string
    ): Promise<Blob> {
        const url = `${APP_CONFIG.backendAPIBaseUrl}/modules-versions/${moduleVersionId}/buckets-and-cups/${bucketsAndCupVersionId}`
        console.log(url)
        const resp = await ADAxios.get(url, {
            responseType: 'blob',
            apiActionName: ApiActionNames.GetBucketsAndCupsCSV,
        })
        return new Blob([resp.data], { type: 'application/zip' })
    }

    public static async getModuleHistory(moduleId: string): Promise<ModuleHistoryDTO[]> {
        try {
            const { data }: AxiosResponse<ModuleHistoryDTOResponse> = await ADAxios.get(
                `${APP_CONFIG.backendAPIBaseUrl}/modules/${moduleId}/history`,
                {
                    apiActionName: ApiActionNames.GetModuleHistory,
                    responseType: 'json',
                }
            )
            return data.history
        } catch (e: unknown) {
            handleAxiosError(e)
        }
    }

    public static async archiveModule(moduleVersionId: string, archive: boolean) {
        try {
            const { data } = await ADAxios.put(
                `${APP_CONFIG.backendAPIBaseUrl}/modules-versions/${moduleVersionId}/archive`,
                {
                    archived: archive,
                },
                {
                    apiActionName: ApiActionNames.ArchiveModule,
                    responseType: 'json',
                }
            )
            return data.archived
        } catch (e) {
            console.error(e)
            handleAxiosError(e)
        }
    }

    public static async getNewestModuleVersion(
        moduleId: string,
        excludedStatuses?: ModuleStatus[]
    ) {
        try {
            const moduleHist = await ModuleService.getModuleHistory(moduleId)
            if (!excludedStatuses || excludedStatuses.length === 0) {
                return moduleHist[moduleHist.length - 1].versionId
            }
            for (const moduleData of moduleHist.reverse()) {
                const moduleStatus: ModuleStatus = ModuleStatus[moduleData.status] as ModuleStatus
                if (!excludedStatuses.includes(moduleStatus)) {
                    return moduleData.versionId
                }
            }
            return ''
        } catch (e) {
            console.error(e)
            return ''
        }
    }

    public static async latestLiveOrPublishedVersion(moduleId: string) {
        try {
            const moduleHist = await ModuleService.getModuleHistory(moduleId)
            return moduleHist.reverse().find((historyEntry) => {
                return (
                    historyEntry.status === ModuleStatus.LIVE.toUpperCase() ||
                    historyEntry.status === ModuleStatus.PUBLISHED.toUpperCase()
                )
            })?.versionId
        } catch (e) {
            console.error(e)
            throw e
        }
    }

    public static async getModuleItemGroupLabels(moduleVersionId: string) {
        const labels: string[] = []
        const moduleDTO = await ModuleService.loadModuleVersionDTO(moduleVersionId)
        for (const itemGroup of moduleDTO.content.workflow) {
            if (itemGroup.moduleChild.labels) {
                for (const label of itemGroup.moduleChild.labels) {
                    labels.push(label)
                }
            }
        }
        return labels
    }
}
