import React, { useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import _ from 'lodash'

import { useAsync } from '@amzn/stencil-react-components/hooks'

import { ReviewDTO } from 'src/models/dto/approval/ReviewDTO'
import { defaultModuleDTO } from 'src/models/dto/ModuleDTO'
import { ModuleStatus } from 'src/models/dto/ModuleStatus'
import { ModuleReviewStatusDTO, ModuleVersionDTO } from 'src/models/dto/ModuleVersionDTO'
import { moduleViewerRoute, useAsyncWithReload } from 'src/pages/module-review/index'
import { ApprovalService } from 'src/services/approval/ApprovalService'
import { ModulePublishService } from 'src/services/backend/ModulePublishService'
import { ModuleService } from 'src/services/backend/ModuleService'

export function useLoadModuleReviewDataBasedOnParams() {
    const { moduleVersionId, reviewId, revisionNumber } = useParams()

    const {
        data: moduleVersionDTO,
        isLoading: isModuleLoading,
        error: moduleError,
        reload,
    } = useAsyncWithReload<ModuleVersionDTO>(() => {
        if (moduleVersionId) {
            return ModuleService.loadModuleVersionDTO(moduleVersionId)
        }
        console.warn('useLoadModuleReviewData: no module loaded', {
            moduleVersionId,
            reviewId,
            revisionNumber,
        })
        return Promise.resolve({ content: [] } as unknown as ModuleVersionDTO)
    }, [moduleVersionId])

    const moduleDTO = useMemo(
        () => moduleVersionDTO?.content ?? defaultModuleDTO(),
        [moduleVersionDTO]
    )
    const lastModified = useMemo(() => {
        let getDisplayStatus = ModuleStatus.DRAFT_UNVALIDATED
        if (moduleVersionDTO) {
            getDisplayStatus = moduleVersionDTO.archived
                ? ModuleStatus.ARCHIVED
                : ModuleStatus[moduleVersionDTO.status]
        }
        return {
            author: moduleVersionDTO?.author ?? 'author',
            date: moduleVersionDTO?.updatedAt ?? new Date(),
            status: getDisplayStatus,
        }
    }, [moduleVersionDTO])

    const {
        data: review,
        isLoading: isReviewLoading,
        error: reviewError,
    } = useAsync<ReviewDTO | null>(
        () => (reviewId ? ApprovalService.getReview(reviewId) : Promise.resolve(null)),
        [reviewId]
    )

    const selectedRev = useMemo(
        () =>
            review && revisionNumber
                ? review.revisionList.find((r) => r.revisionNumber === revisionNumber)
                : null,
        [review, revisionNumber]
    )
    const selectedRevError =
        review?.revisionList && !selectedRev
            ? `Revision not found: ${
                  revisionNumber || ''
              }. Please select a correct revision from the dropdown.`
            : ''
    const error = moduleError?.message || reviewError?.message || selectedRevError
    const isLoading = isModuleLoading || isReviewLoading
    return {
        moduleVersion: moduleVersionDTO,
        review,
        module: moduleDTO,
        lastModified,
        error,
        isLoading,
        reload,
    }
}

/**
 * Examine the module review route and auto-redirect to the correct route if the moduleVersionId or revisionNumber
 * mismatches the module review data retrieved from the API.
 */
export function useEnsureModuleReviewRouteConsistency() {
    const { moduleVersionId, reviewId, revisionNumber } = useParams()
    const navigate = useNavigate()

    const {
        data: moduleReviewStatus,
        isLoading,
        error: loadError,
    } = useAsync(() => {
        return reviewId
            ? ModulePublishService.getModuleReviewStatusByReviewId(reviewId)
            : Promise.resolve(null)
    }, [moduleVersionId, reviewId])
    const [error, setError] = useState<string | undefined>()

    useEffect(() => {
        if (isLoading) {
            setError('')
            return
        }

        if (!moduleReviewStatus?.length) {
            setError('Unable to retrieve module review status.')
            return
        }

        if (!reviewId) {
            setError('Missing reviewId.')
            return
        }

        // Ensure API result returns a review with the correct reviewId
        const mismatchReview = moduleReviewStatus.some((e) => e.reviewId !== reviewId)
        const prevParams = { moduleVersionId, reviewId, revisionNumber }

        // Ensure revisionNumber corresponds to an existing revision
        const revisionNotFound =
            !revisionNumber ||
            moduleReviewStatus.findIndex(
                (e) => `${e.revisionNumber}` === `${revisionNumber ?? ''}`
            ) === -1
        if (revisionNotFound || mismatchReview) {
            if (mismatchReview) {
                console.log('useEnsureModuleReviewRouteConsistency: mismatched review ID', {
                    prevParams,
                    moduleReviewStatus,
                })
            } else {
                console.log('useEnsureModuleReviewRouteConsistency: revision not found', {
                    prevParams,
                    moduleReviewStatus,
                })
            }

            // Redirect to the latest revision
            const latestRevision = _.last<ModuleReviewStatusDTO>(
                _.sortBy(moduleReviewStatus, 'createdAt')
            )
            if (latestRevision?.versionId) {
                const newRoute = moduleViewerRoute({
                    moduleVersionId: latestRevision.versionId,
                    reviewId: latestRevision.reviewId,
                    revisionNumber: `${latestRevision.revisionNumber}`,
                })
                console.log('useEnsureModuleReviewRouteConsistency: wrong/missing revision', {
                    prevParams,
                    newRoute,
                })
                navigate(newRoute)
                setError('')
                return
            }
            setError('Unable to determine the correct revision due to missing data.')
            return
        }

        // Ensure moduleVersionId corresponds to the review
        const entry = moduleReviewStatus.find(
            (e) => `${e.revisionNumber}` === revisionNumber && e.reviewId === reviewId
        )
        if (entry?.versionId && entry.versionId !== moduleVersionId) {
            const newRoute = moduleViewerRoute({
                moduleVersionId: entry.versionId,
                reviewId,
                revisionNumber,
            })
            console.log('useEnsureModuleReviewRouteConsistency: wrong module version ID', {
                prevParams,
                newRoute,
            })
            navigate(newRoute)
            setError('')
        }
    }, [isLoading, moduleReviewStatus, moduleVersionId, navigate, reviewId, revisionNumber])

    return { error: isLoading ? undefined : loadError?.message || error }
}

function usePrevious<T>(value: T) {
    const ref = React.useRef(value)

    React.useEffect(() => {
        ref.current = value
    })

    return ref.current
}

export default usePrevious
