import React, { useEffect, useState } from 'react'
import { Link as RouterLink, useParams } from 'react-router-dom'

import { TimerStopwatch } from '@amzn/katal-metrics/lib/metricObject'
import { useAsync } from '@amzn/stencil-react-components/hooks'
import { Col, Container, Flex, Row, Spacer, View } from '@amzn/stencil-react-components/layout'
import { MessageBanner, MessageBannerType } from '@amzn/stencil-react-components/message-banner'
import { Spinner } from '@amzn/stencil-react-components/spinner'
import { H2 } from '@amzn/stencil-react-components/text'

import { LocaleDropdown, LocaleSelectorContainer } from 'src/components/LocaleSelector'
import { MastheadContainer } from 'src/components/MastheadContainer'
import { LAYOUT_DIRECTION, useLayoutDirection } from 'src/components/ModuleDisplayTable'
import { LastModifiedSubtitle } from 'src/components/ModuleMasthead'
import { ModuleOptions, OptionValues } from 'src/components/ModuleOptions'
import { ModulePreview } from 'src/components/ModulePreview'
import { useQueryParams } from 'src/hooks/useQueryParams'
import { useTitle } from 'src/hooks/useTitle'
import {
    CommonUserEventKeys,
    publishTimeSpentMetrics,
    publishUserEventMetrics,
    UserEventMethodNames,
} from 'src/metrics'
import { ApprovalDTO } from 'src/models/dto/approval/ApprovalDTO'
import { UATReviewDTO } from 'src/models/dto/approval/UATReviewDTO'
import { Locale } from 'src/models/dto/Locale'
import { ModuleLastModifiedDTO } from 'src/models/dto/ModuleLastModifiedDTO'
import { ModuleStatus } from 'src/models/dto/ModuleStatus'
import { useLoadModuleReviewDataBasedOnParams } from 'src/pages/module-review/hooks'
import { ModuleReviewButtonsWrapper } from 'src/pages/module-review/ModuleReviewButtonsWrapper'
import {
    ModuleUATApprovalBanner,
    UATModuleApprovalComponents,
} from 'src/pages/module-review/UATApprovalComponents'
import { ApprovalService } from 'src/services/approval/ApprovalService'
import { Authenticator } from 'src/services/Authenticator'
import { ModuleService } from 'src/services/backend/ModuleService'

const pageTimer = new TimerStopwatch('ModuleViewerPageTimer')

export const ModuleViewerPage = () => {
    const {
        moduleVersion,
        module: moduleDTO,
        lastModified,
        error,
        isLoading,
        reload: reloadModuleReviewData,
    } = useLoadModuleReviewDataBasedOnParams()

    const [uatReview, setUATReview] = useState<UATReviewDTO>()
    const [modulePreviewUnexpectedError, setModulePreviewUnexpectedError] = useState<
        string | undefined
    >(undefined)
    const [updatedLastModifiedBy, setUpdatedLastModifiedBy] =
        useState<ModuleLastModifiedDTO>(lastModified)

    const { moduleVersionId } = useParams()
    const [latestVersionIdWarning, setLatestVersionIdWarning] = useState<string | undefined>(
        undefined
    )

    useEffect(() => {
        setUATReview(moduleVersion?.uatReviewStatus)
        setLatestVersionIdWarning(undefined)
    }, [moduleVersion])

    useEffect(() => {
        if (moduleVersion) {
            setUpdatedLastModifiedBy({
                ...lastModified,
                status: moduleVersion.archived
                    ? ModuleStatus.ARCHIVED
                    : (moduleVersion.status as ModuleStatus),
            })
        }
    }, [lastModified, moduleVersion])

    const {
        data: uatApprovals,
        isLoading: isUATApprovalLoading,
        error: uatApprovalLoadingError,
    } = useAsync<ApprovalDTO[]>(() => {
        if (uatReview) {
            return ApprovalService.listApproval({
                reviewId: uatReview.reviewId,
                revisionNumber: uatReview.revisionNumber,
            })
        }

        return new Promise(() => [] as ApprovalDTO[])
    }, [uatReview])

    useEffect(() => {
        if (moduleDTO) {
            ModuleService.populateModule(moduleDTO)

            ModuleService.getNewestModuleVersion(moduleDTO.id)
                .then((latestVersionId) => {
                    if (latestVersionId !== moduleDTO.version) {
                        setLatestVersionIdWarning(latestVersionId)
                    } else {
                        setLatestVersionIdWarning(undefined)
                    }
                })
                .catch(() => {})
        }
    }, [moduleDTO])

    useTitle(() => `${moduleDTO?.name ?? '<unknown>'} - Module Viewer`, [moduleDTO])
    const layoutDirection = useLayoutDirection()
    const [locale, setLocale] = useState<Locale>(Locale.en_US)
    const queryParams = useQueryParams()

    useEffect(() => {
        pageTimer.start()
        publishUserEventMetrics(UserEventMethodNames.ModuleViewer, CommonUserEventKeys.Open)

        return () => {
            pageTimer.stop()
            publishTimeSpentMetrics(UserEventMethodNames.ModuleViewer, pageTimer.value)
        }
    }, [])

    if (isLoading && isUATApprovalLoading) {
        return (
            <Container
                backgroundColor='#f2f2f2'
                minHeight='100vh'
                className='module-preview-container'
            >
                <Col gridGap='S200'>
                    <Row justifyContent='center' gridGap='S200' margin='S200'>
                        <Spinner />
                    </Row>
                </Col>
            </Container>
        )
    }
    const errorPart = error && <MessageBanner type={MessageBannerType.Error}>{error}</MessageBanner>
    const loadingUATApprovalErrorBanner = uatApprovalLoadingError && (
        <MessageBanner type={MessageBannerType.Error}>
            {uatApprovalLoadingError?.message}
        </MessageBanner>
    )

    const dropDownOptions = new Set([
        OptionValues.LAUNCH_PREVIEW,
        OptionValues.OPEN_REVIEW,
        OptionValues.SHARE_PREVIEW,
        OptionValues.COPY_MODULE_ID,
        OptionValues.DOWNLOAD_CSV,
        OptionValues.DOWNLOAD_JSON,
        OptionValues.ARCHIVE_MODULE,
    ])

    const onArchive = (isArchived: boolean) => {
        setUpdatedLastModifiedBy({
            ...lastModified,
            status: isArchived ? ModuleStatus.ARCHIVED : (moduleVersion?.status as ModuleStatus),
        })
    }

    if (
        updatedLastModifiedBy.status !== ModuleStatus.DRAFT_UNVALIDATED ||
        updatedLastModifiedBy.author === Authenticator.getDefaultUser()
    ) {
        dropDownOptions.add(OptionValues.OPEN_EDITOR)
    }

    if (ModuleStatus.UAT === updatedLastModifiedBy.status) {
        dropDownOptions.add(OptionValues.REQUEST_TRANSLATIONS)
    }

    if (
        updatedLastModifiedBy.status === ModuleStatus.REVIEW_APPROVED ||
        queryParams.get('enableDeploy')
    ) {
        dropDownOptions.add(OptionValues.DEPLOY_UAT)
    } else if (updatedLastModifiedBy.status === ModuleStatus.UAT) {
        dropDownOptions.add(OptionValues.OPEN_UAT)
    }

    if (queryParams.get('enableDeploy')) {
        dropDownOptions.add(OptionValues.DEPLOY_PROD)
    }

    return (
        <>
            {module && (
                <Col
                    backgroundColor='neutral0'
                    width='100%'
                    margin={0}
                    padding={0}
                    dataTestId='module-review-page'
                >
                    {modulePreviewUnexpectedError && (
                        <MessageBanner
                            type={MessageBannerType.Error}
                            dismissButtonAltText={'Hide errors'}
                            dataTestId={'unexpected-error-banner'}
                            isDismissible={true}
                            onDismissed={() => setModulePreviewUnexpectedError(undefined)}
                        >
                            {modulePreviewUnexpectedError}
                        </MessageBanner>
                    )}
                    {latestVersionIdWarning && (
                        <MessageBanner
                            type={MessageBannerType.Warning}
                            dismissButtonAltText={'Hide warning'}
                            dataTestId={'latest-version-warning-banner'}
                            isDismissible={true}
                            onDismissed={() => setLatestVersionIdWarning(undefined)}
                        >
                            This is not the latest version of this module. To visit the latest
                            version, click{' '}
                            <RouterLink to={`/module/viewer/${latestVersionIdWarning}`}>
                                <strong>here</strong>
                            </RouterLink>{' '}
                        </MessageBanner>
                    )}
                    <MastheadContainer>
                        <View>
                            <H2 fontSize='T500'>{moduleDTO.name}</H2>
                            {updatedLastModifiedBy && (
                                <LastModifiedSubtitle lastModified={updatedLastModifiedBy} />
                            )}
                        </View>
                        <ModuleReviewButtonsWrapper>
                            <UATModuleApprovalComponents
                                moduleVersionId={moduleVersionId}
                                uatReview={uatReview}
                                moduleStatus={
                                    updatedLastModifiedBy.status as unknown as ModuleStatus
                                }
                                uatApprovals={uatApprovals}
                                onApprovalChange={() => {
                                    reloadModuleReviewData()
                                }}
                                onReviewCreated={(createdUatReview: UATReviewDTO) => {
                                    setUATReview(createdUatReview)
                                }}
                                onDeployModalClose={() => {
                                    reloadModuleReviewData()
                                }}
                            />
                            {updatedLastModifiedBy && (
                                <ModuleOptions
                                    moduleEntity={{ ...moduleDTO, workflowIds: [] }}
                                    moduleStatus={updatedLastModifiedBy.status}
                                    reviewStatus={moduleVersion?.moduleReviewStatus}
                                    optionsToInclude={dropDownOptions}
                                    onPreviewUnexpectedError={() =>
                                        setModulePreviewUnexpectedError(
                                            'An error has occurred while previewing the module. Please try again later.'
                                        )
                                    }
                                    onArchive={onArchive}
                                />
                            )}
                        </ModuleReviewButtonsWrapper>
                    </MastheadContainer>
                    <LocaleSelectorContainer>
                        <LocaleDropdown
                            id='module-review-locales'
                            {...{ locale, setLocale }}
                            options={moduleDTO.availableLocales}
                        />
                    </LocaleSelectorContainer>
                </Col>
            )}
            <Container
                backgroundColor='neutral05'
                width='100%'
                paddingHorizontal={0}
                minHeight='100vh'
            >
                <Spacer height='S300' />
                <Col gridGap='S300'>
                    {errorPart}
                    {loadingUATApprovalErrorBanner}
                </Col>
                <ModuleUATApprovalBanner
                    moduleStatus={updatedLastModifiedBy.status}
                    uatApprovals={uatApprovals}
                />
                <Spacer height='S300' />
                {module && (
                    <Flex
                        gridGap='S400'
                        flexDirection={
                            layoutDirection === LAYOUT_DIRECTION.DESKTOP ? 'row' : 'column'
                        }
                        padding={
                            layoutDirection === LAYOUT_DIRECTION.DESKTOP
                                ? { left: 'S400', right: 'S400' }
                                : undefined
                        }
                    >
                        <View flex='1'>
                            <ModulePreview {...{ moduleDTO, locale }} />
                        </View>
                    </Flex>
                )}
            </Container>
        </>
    )
}
