import React, { useCallback, useEffect, useState } from 'react'

import { Button, ButtonVariant } from '@amzn/stencil-react-components/button'
import { IconAlertTriangleFill } from '@amzn/stencil-react-components/icons'
import { Spacer } from '@amzn/stencil-react-components/layout'
import { MessageBanner, MessageBannerType } from '@amzn/stencil-react-components/message-banner'
import { Modal, ModalContent } from '@amzn/stencil-react-components/modal'
import { ScreenReaderOnly } from '@amzn/stencil-react-components/screen-reader-only'
import { TabBar, TabPanel, TabSwitcher, useTabs } from '@amzn/stencil-react-components/tabs'
import { H1 } from '@amzn/stencil-react-components/text'

import { EXPERIMENTAL_FEATURE_ACCESS } from 'src/config.app'
import {
    GetAdditionalTableCells,
    ItemPoolEditor,
    ItemPoolGeneratorContext,
} from 'src/pages/module-builder/item-pool-editor/ItemPoolEditor'
import {
    ManualItemPoolBuilder,
    NOT_ITEM_POOL_ALLOWED_STATUS,
} from 'src/pages/module-builder/SelfServeManualItemPoolBuilder/ManualItemPoolBuilder'
import { ModuleService } from 'src/services/backend/ModuleService'
import { GetProgressResponseStatus, ItemPoolService } from 'src/services/item-pools/ItemPoolService'

interface ItemPoolModalProps {
    existingPool: boolean
    itemPoolVersionId?: string
    setSelectItemPoolVersionId?: (poolVersionId: string) => void
    disabledItemPoolModal?: boolean
}

export const TAB_NAMES = {
    MANUAL_ITEM_POOL: 'Manual Item Pool',
    TIER_ONE_ITEM_POOL: 'Tier One Item Pool',
}

export const ItemPoolModal = ({
    existingPool,
    itemPoolVersionId,
    setSelectItemPoolVersionId,
    disabledItemPoolModal,
}: ItemPoolModalProps) => {
    const [validItemPool, setValidItemPool] = useState(false)
    const [itemPoolMessage, setItemPoolMessage] = useState('')
    const [hasOldModules, setHasOldModules] = useState(false)
    const [isModalOpen, setIsModalOpen] = useState(false)

    useEffect(() => {
        const checkValidItemPool = async () => {
            const VERSION_ID_REGEX =
                /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/

            if (itemPoolVersionId && VERSION_ID_REGEX.test(itemPoolVersionId)) {
                try {
                    const response = await ItemPoolService.getProgress(itemPoolVersionId)
                    switch (response.status) {
                        case GetProgressResponseStatus.COMPLETED: {
                            const itemPool = await ItemPoolService.getItemPoolItems(
                                itemPoolVersionId
                            )
                            if (itemPool) {
                                for (const itemContainer of itemPool) {
                                    const module = await ModuleService.loadModuleVersionDTO(
                                        itemContainer.versionId
                                    )
                                    const newestVersionId =
                                        await ModuleService.getNewestModuleVersion(
                                            module.id,
                                            NOT_ITEM_POOL_ALLOWED_STATUS
                                        )
                                    if (itemContainer.versionId !== newestVersionId) {
                                        setHasOldModules(true)
                                    }
                                }
                            }
                            setValidItemPool(true)
                            break
                        }
                        case GetProgressResponseStatus.IN_PROGRESS:
                            setItemPoolMessage('Item pool still being created')
                            break
                        case GetProgressResponseStatus.IN_ERROR:
                            setItemPoolMessage('Error occurred when accessing item pool')
                            break
                    }
                } catch (e) {
                    setItemPoolMessage('Error occurred when accessing item pool')
                }
            } else {
                setItemPoolMessage('Not a valid item pool version id')
            }
        }
        setHasOldModules(false)
        setValidItemPool(false)
        void checkValidItemPool()
    }, [itemPoolVersionId])

    const setVersionId = useCallback(
        (versionId: string) => {
            if (setSelectItemPoolVersionId) {
                setSelectItemPoolVersionId(versionId)
            }
            setIsModalOpen(false)
        },
        [setSelectItemPoolVersionId]
    )

    const SelectCell = useCallback(
        ({ data: versionId }: { data: string }) => {
            return (
                <Button
                    dataTestId='select-item-pool-version-id'
                    onClick={() => setVersionId(versionId)}
                    variant={ButtonVariant.Secondary}
                >
                    Select
                </Button>
            )
        },
        [setVersionId]
    )

    const getAdditionalTableCells: GetAdditionalTableCells = useCallback(() => {
        return [
            {
                accessor: 'versionId',
                header: <ScreenReaderOnly>Select</ScreenReaderOnly>,
                cellComponent: SelectCell,
                useMinimalSpacing: true,
            },
        ]
    }, [SelectCell])

    const tabs = EXPERIMENTAL_FEATURE_ACCESS()
        ? [TAB_NAMES.MANUAL_ITEM_POOL, TAB_NAMES.TIER_ONE_ITEM_POOL]
        : [TAB_NAMES.TIER_ONE_ITEM_POOL]
    const { tabBarProps, tabSwitcherProps } = useTabs({ tabs })
    return (
        <div>
            <Modal
                close={() => {
                    setIsModalOpen(false)
                }}
                isScrollable={false}
                isOpen={isModalOpen}
                dataTestId={'item-pool-modal'}
            >
                <ModalContent titleText='Item Pool Builder' maxWidth='85vw'>
                    <div style={{ minWidth: '70vw', minHeight: '60vh' }}>
                        <TabBar {...tabBarProps} />
                        <TabSwitcher {...tabSwitcherProps}>
                            <TabPanel value={TAB_NAMES.MANUAL_ITEM_POOL}>
                                <Spacer height={'S200'} />
                                <MessageBanner
                                    dismissButtonAltText='Dismiss this message'
                                    isDismissible={false}
                                    type={MessageBannerType.Warning}
                                >
                                    Manual Item Pool: Creates an item pool from one or more Item
                                    Containers entered below. Typically used in Random Selection
                                    Groups or Buckets & Cups Groups.
                                </MessageBanner>
                                {!existingPool ? (
                                    <ManualItemPoolBuilder
                                        isExisting={false}
                                        setSelectItemPoolVersionId={setVersionId}
                                    />
                                ) : !validItemPool ? (
                                    <H1>{itemPoolMessage}</H1>
                                ) : (
                                    <ManualItemPoolBuilder
                                        isExisting={true}
                                        poolVersionId={itemPoolVersionId}
                                        setSelectItemPoolVersionId={setVersionId}
                                    />
                                )}
                            </TabPanel>
                            <TabPanel value={TAB_NAMES.TIER_ONE_ITEM_POOL}>
                                <Spacer height={'S200'} />
                                <MessageBanner
                                    dismissButtonAltText='Dismiss this message'
                                    isDismissible={false}
                                    type={MessageBannerType.Warning}
                                >
                                    Tier One Item Pool: Generates an item pool of either Ranking or
                                    Slot Picker items in a layout originally designed for Tier1.
                                    (Not used for general Buckets & Cups Groups or Random Selection
                                    Groups.)
                                </MessageBanner>
                                <ItemPoolGeneratorContext.Provider
                                    value={{ getAdditionalTableCells }}
                                >
                                    <ItemPoolEditor />
                                </ItemPoolGeneratorContext.Provider>
                            </TabPanel>
                        </TabSwitcher>
                    </div>
                </ModalContent>
            </Modal>
            <Button
                dataTestId='open-item-pool-modal'
                aria-label={'open item pool modal'}
                variant={ButtonVariant.Secondary}
                onClick={() => {
                    setIsModalOpen(true)
                }}
                icon={
                    existingPool && hasOldModules ? (
                        <IconAlertTriangleFill aria-hidden={true} />
                    ) : null
                }
                aria-disabled={disabledItemPoolModal}
            >
                Item Pool
            </Button>
        </div>
    )
}
