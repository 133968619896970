import React, { useCallback, useEffect, useState } from 'react'

import { ButtonVariant } from '@amzn/stencil-react-components/button'
import { Col } from '@amzn/stencil-react-components/layout'
import { ModalContent, WithModal } from '@amzn/stencil-react-components/modal'
import { useBreakpoints } from '@amzn/stencil-react-components/responsive'
import { Spinner } from '@amzn/stencil-react-components/spinner'
import { Text } from '@amzn/stencil-react-components/text'

import { Button } from 'src/components/Button'
import { DropdownButton } from 'src/components/DropdownButton'
import { PreviewErrorModal } from 'src/components/ErrorModals'
import { useActivityEntity, useEntity } from 'src/hooks/useEntity'
import { usePagePreview } from 'src/hooks/usePagePreview'
import { ModuleValidationErrorMessage } from 'src/hooks/usePreview'
import { isBucketsAndCupsGroupEntity } from 'src/models/dto/activities/BucketsAndCupsGroupDTO'
import { ItemGroupDTO, ItemGroupEntity } from 'src/models/dto/activities/ItemGroupDTO'
import { MUPPExamDTO } from 'src/models/dto/activities/MUPPExamDTO'
import { RandomSelectionDTO } from 'src/models/dto/activities/RandomSelectionGroupDTO'
import { ActivityEntity, ActivityType } from 'src/models/dto/ActivityDTO'
import { ModuleEntity, ModuleLayout } from 'src/models/dto/ModuleDTO'
import { ModuleStatus } from 'src/models/dto/ModuleStatus'
import { END_OF_MODULE_FOCUS_ID, focusOnId, pageFocusId } from 'src/pages/module-builder/focus'
import { ErrorCheckService } from 'src/services/backend/ErrorCheckService'
import { ModuleService } from 'src/services/backend/ModuleService'
import { ActivityEntityService } from 'src/services/EntityServices/ActivityEntityService'
import { BucketsAndCupsGroupHandler } from 'src/services/EntityServices/ActivityUpdateHandlers/BucketsAndCupsGroupHandler'
import { ItemEntityService } from 'src/services/EntityServices/ItemEntityService'
import {
    MODULE_ENTITY_STORE_SELECTOR,
    ModuleEntityService,
} from 'src/services/EntityServices/ModuleEntityService'

enum PageOptionsModal {
    DELETE_PAGE = 'DELETE_PAGE',
}

interface PageOptionsProps {
    pageIndex: number
    moduleEntityId: string
    activityId: string
    onPreviewValidationError?: (validationErrors: ModuleValidationErrorMessage[]) => void
    onPreviewUnexpectedError?: () => void
    onErrorCheck?: (hasValidationError: boolean) => void
    editDisabled?: boolean
    runErrorCheckDisabled?: boolean
    moduleStatus?: ModuleStatus
}

interface RenderModalPropsPageOptions {
    itemGroupDTO?: ItemGroupDTO
    onClose: () => void
    onDone: () => void
}

function DeletePageModal({ onClose, onDone }: RenderModalPropsPageOptions) {
    return (
        <ModalContent
            titleText='Delete page'
            buttons={[
                // @ts-ignore: Expression produces a union type that is too complex to represent error
                <Button
                    key='delete-page-modal-submit'
                    dataTestId='delete-page-modal-submit'
                    onClick={() => {
                        onDone()
                        onClose()
                    }}
                    isDestructive
                >
                    Yes, delete
                </Button>,
                <Button
                    key='close-modal'
                    dataTestId='close-modal'
                    onClick={onClose}
                    variant={ButtonVariant.Primary}
                >
                    No, keep page
                </Button>,
            ]}
        >
            <Col gridGap='S400'>
                <Text>Do you want to delete this page? This cannot be undone.</Text>
            </Col>
        </ModalContent>
    )
}

export function PageOptions({
    pageIndex,
    moduleEntityId,
    activityId,
    onPreviewUnexpectedError,
    onPreviewValidationError,
    onErrorCheck,
    editDisabled,
    runErrorCheckDisabled,
    moduleStatus,
}: PageOptionsProps) {
    const [openModal, setOpenModal] = useState<PageOptionsModal | null>(null)
    const {
        matches: { s: small },
    } = useBreakpoints()

    const { entity } = useActivityEntity<ActivityEntity>({
        workflowEntityId: activityId,
        moduleEntityId: moduleEntityId,
    })

    const { entity: module } = useEntity<ModuleEntity>({
        entityId: moduleEntityId,
        selector: MODULE_ENTITY_STORE_SELECTOR,
    })

    const { generatingPreview, previewValidationError, previewUrl, executePreview } =
        usePagePreview({
            activityId: activityId,
            onPreviewUnexpectedError: onPreviewUnexpectedError,
            moduleLayout: module.layout ?? ModuleLayout.Default,
        })

    const [previewErrorCount, setPreviewErrorCount] = useState<number | undefined>(undefined)
    const [loading, setLoading] = useState(false)

    const downloadBucketsAndCups = useCallback(async () => {
        const data: Blob = await ModuleService.downloadBucketsAndCup(moduleEntityId, activityId)

        const element = document.createElement('a')
        element.href = URL.createObjectURL(data)
        element.download = `Buckets and Cups files- ${activityId}.zip`
        document.body.appendChild(element) // Required for this to work in FireFox
        element.click()
    }, [moduleEntityId, activityId])

    const runErrorCheck = useCallback(async () => {
        try {
            const dto = ModuleService.serializeActivityDTO(activityId).moduleChild as
                | ItemGroupDTO
                | RandomSelectionDTO
                | MUPPExamDTO
            setLoading(true)
            await ErrorCheckService.templatesErrorCheck(dto)
            onErrorCheck?.(false)
            setLoading(false)
        } catch (e) {
            onErrorCheck?.(true)
            setLoading(false)
        }
    }, [activityId, onErrorCheck])

    useEffect(() => {
        if (previewUrl) {
            window.open(
                previewUrl,
                'preview',
                'scrollbars=no,menubar=no,toolbar=no,location=no,status=no'
            )
        }
    }, [previewUrl])

    useEffect(() => {
        if (previewValidationError) {
            setPreviewErrorCount(previewValidationError.length)
            ;(async function () {
                await runErrorCheck()
            })().catch(console.error)

            if (onPreviewValidationError) {
                onPreviewValidationError(previewValidationError)
            }
        }
    }, [previewValidationError, onPreviewValidationError, runErrorCheck])

    const dropdownValues = useCallback(
        (open: () => void) => [
            {
                name: 'Preview page',
                dataTestId: 'preview-page',
                onClick: () => executePreview(),
            },
            {
                name: 'Duplicate page',
                disabled: (entity && entity.type === ActivityType.LaunchCAT) || editDisabled,
                dataTestId: 'duplicate-page',
                onClick: () => {
                    const duplicated = ActivityEntityService.duplicate(activityId)
                    ModuleEntityService.addActivity(moduleEntityId, duplicated.id)
                    const nextPageFocusId = pageFocusId(pageIndex + 2)
                    focusOnId(nextPageFocusId)
                },
            },
            {
                name: 'Run error check',
                dataTestId: 'run-page-error-check',
                disabled: runErrorCheckDisabled || editDisabled,
                onClick: () => runErrorCheck(),
            },
            {
                name: 'Delete page',
                dataTestId: 'delete-page',
                disabled: editDisabled,
                onClick: () => {
                    setOpenModal(PageOptionsModal.DELETE_PAGE)
                    open()
                },
            },
            ...(isBucketsAndCupsGroupEntity(entity) &&
            entity.buckets.length > 0 &&
            !entity.updatingCsvFile
                ? [
                      {
                          name: 'Change CSV File',
                          dataTestId: 'change-csv-file',
                          disabled: editDisabled,
                          onClick: () => {
                              BucketsAndCupsGroupHandler.setUpdatingCSV(activityId, true)
                          },
                      },
                      ...(moduleStatus && moduleStatus !== ModuleStatus.DRAFT_UNVALIDATED
                          ? [
                                {
                                    name: 'Download File',
                                    dataTestId: 'download-page',
                                    disabled: editDisabled,
                                    onClick: async () => {
                                        setLoading(true)
                                        await downloadBucketsAndCups()
                                        setLoading(false)
                                    },
                                },
                            ]
                          : []),
                  ]
                : []),
        ],
        [
            entity,
            editDisabled,
            executePreview,
            activityId,
            moduleEntityId,
            pageIndex,
            runErrorCheck,
            runErrorCheckDisabled,
            downloadBucketsAndCups,
            moduleStatus,
        ]
    )

    const renderModal = useCallback(
        ({ close }: { close: () => void }) => {
            return openModal === PageOptionsModal.DELETE_PAGE ? (
                <DeletePageModal
                    onClose={close}
                    onDone={() => {
                        ActivityEntityService.remove(activityId)
                        ModuleEntityService.removeActivity(moduleEntityId, activityId)
                        if (entity.type === ActivityType.LaunchItemGroup) {
                            const itemGroupEntity: ItemGroupEntity = entity as ItemGroupEntity
                            itemGroupEntity.itemIds.forEach((i) => ItemEntityService.remove(i))
                        }
                        const prevPageFocusId = pageFocusId(pageIndex === 0 ? 1 : pageIndex)
                        focusOnId(prevPageFocusId, END_OF_MODULE_FOCUS_ID)
                    }}
                />
            ) : (
                <></>
            )
        },
        [openModal, activityId, moduleEntityId, entity, pageIndex]
    )

    const modalBody = useCallback(
        ({ open }: { open: () => void }) => (
            <DropdownButton
                title='Page options'
                values={dropdownValues(open)}
                dataTestId='page-options-button'
                buttonProps={small ? { style: { width: '100%' } } : {}}
            />
        ),
        [small, dropdownValues]
    )

    if (generatingPreview || loading) {
        return <Spinner dataTestId='preview-spinner' />
    }

    return (
        <>
            <PreviewErrorModal
                title={'Preview page'}
                close={() => setPreviewErrorCount(undefined)}
                errorCount={previewErrorCount}
            />
            <WithModal isScrollable renderModal={renderModal}>
                {modalBody}
            </WithModal>
        </>
    )
}
