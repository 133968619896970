import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import _ from 'lodash'

import { InputWrapper, Select, TextArea } from '@amzn/stencil-react-components/form'
import { Col, Hr, Spacer, View } from '@amzn/stencil-react-components/layout'
import { ScreenReaderOnly } from '@amzn/stencil-react-components/screen-reader-only'
import { Spinner } from '@amzn/stencil-react-components/spinner'
import { H3, H4, Label } from '@amzn/stencil-react-components/text'

import { Markdown } from 'src/components/Markdown'
import { getReviewersList } from 'src/config.app'
import { ModuleReviewContext } from 'src/contexts/ModuleReviewContext'
import { CommentDTO } from 'src/models/dto/approval/CommentDTO'
import {
    CreateModuleReviewInput,
    ModuleGroupReviewRequest,
    ReviewDTO,
} from 'src/models/dto/approval/ReviewDTO'
import { AddCommentForm, Comment } from 'src/pages/module-review/Comments'
import { Authenticator } from 'src/services/Authenticator'

export interface ModuleReviewProps {
    moduleVersionId: string
    review: ReviewDTO
    revisionNumber: string
}

export function ModuleReviewComments({
    comments,
    isCommentsLoading = false,
}: {
    comments: CommentDTO[]
    isCommentsLoading?: boolean
}) {
    const commentsSorted = useMemo(() => _.orderBy(comments || [], 'createdAt', 'desc'), [comments])
    return (
        <Col padding={{ top: 'S200' }} gridGap='S300'>
            {isCommentsLoading && <Spinner dataTestId='comments-loading-spinner' />}
            {commentsSorted.length === 0 ? (
                <View>No comments.</View>
            ) : (
                commentsSorted.map((c, i, { length }) => (
                    <Comment key={i} comment={c} label={`Comment ${i + 1} of ${length}`} />
                ))
            )}
        </Col>
    )
}

export function ModuleReviewBox(props: ModuleReviewProps) {
    const { isCommentsLoading, comments } = useContext(ModuleReviewContext)
    const { review, revisionNumber } = props
    const { reviewId, title, requester, revisionList, reviewers } = review
    const revision = useMemo(() => {
        if (!revisionList) {
            return null
        }

        return (
            revisionList.find((r) => r.revisionNumber.toString() === revisionNumber) ??
            _.last(revisionList)
        )
    }, [revisionList, revisionNumber])

    const commentRef = useRef<HTMLDivElement | null>(null)

    const onCommentPosted = useCallback(() => {
        commentRef?.current?.focus()
    }, [commentRef])

    return (
        <Col gridGap='S400' dataTestId='module-review-box'>
            <View>
                <H3 fontSize='T500'>Review</H3>
                <Hr />
                <Spacer height='S300' />
                <Col gridGap='S100'>
                    <View>Title: {title}</View>
                    <View>Requester: {requester}</View>
                    {reviewers.length > 0 && <View>Reviewers: {reviewers.join(', ')}</View>}
                    <View>Revision: {revision?.revisionNumber ?? ''}</View>
                    <View>
                        <Markdown markdown={'Description: ' + (revision?.description ?? '')} />
                    </View>
                </Col>
            </View>
            <View>
                <Label htmlFor='module-comment-textarea' id='comments-label'>
                    <H4 fontSize='T400' aria-describedby='comments-heading-sro-only'>
                        Comments
                    </H4>
                    <ScreenReaderOnly id='comments-heading-sro-only'>
                        {`There are ${(comments ?? []).length} comment(s)`}
                    </ScreenReaderOnly>
                </Label>
                <Hr />
                <Spacer height='S200' />
                <View>
                    <AddCommentForm
                        commentTextAreaId={'module-comment-textarea'}
                        commentTextAreaLabelledBy={'comments-label'}
                        onCommentPosted={onCommentPosted}
                        {...{ reviewId, revisionNumber }}
                    />
                </View>
                <Spacer height='S300' />
                <View
                    aria-label={`${
                        (comments ?? []).length
                    } comments, sorted by latest comment first`}
                    tabIndex={-1}
                    ref={commentRef}
                    style={{ maxHeight: 600, overflow: 'auto' }}
                >
                    <ModuleReviewComments
                        comments={comments || []}
                        isCommentsLoading={isCommentsLoading}
                    />
                </View>
            </View>
        </Col>
    )
}

interface ModuleReviewFormProps {
    versionId: string
    onCreateReviewInputChange?: (review: CreateModuleReviewInput) => void
    onCreateGroupReviewInputChange?: (review: ModuleGroupReviewRequest) => void
    reviewIds?: string[]
    moduleName: string
    callingFrom: string
    descriptionError: boolean
    setDescriptionError: (descriptionError: boolean) => void
}

export function ModuleReviewForm(props: ModuleReviewFormProps) {
    const {
        versionId,
        reviewIds,
        onCreateReviewInputChange,
        onCreateGroupReviewInputChange,
        moduleName,
        callingFrom,
        descriptionError,
        setDescriptionError,
    } = props
    const reviewId = useMemo(() => (reviewIds?.length ? reviewIds[0] : ''), [reviewIds])
    const requester = Authenticator.getDefaultUser()
    const [description, setDescription] = useState('')
    const [reviewers, setReviewers] = useState([])

    useEffect(() => {
        if (callingFrom === 'module') {
            onCreateReviewInputChange?.({
                moduleVersionId: versionId,
                reviewId: reviewId || undefined,
                title: moduleName,
                description,
                requester,
                reviewers: reviewers,
                reviewUrl: window.location.origin.concat(`/module/viewer/${versionId}/review`),
            })
        }

        if (callingFrom === 'moduleGroup') {
            onCreateGroupReviewInputChange?.({
                versionId: versionId,
                title: moduleName,
                description,
                reviewers: reviewers,
                reviewUrl: window.location.origin.concat(`/module-groups/${versionId}/review`),
            })
        }
    }, [
        onCreateReviewInputChange,
        onCreateGroupReviewInputChange,
        description,
        reviewers,
        requester,
        reviewId,
        moduleName,
        versionId,
        callingFrom,
    ])

    return (
        <>
            <InputWrapper
                id='request-review-description'
                labelText='Description'
                error={descriptionError}
                footer={
                    descriptionError
                        ? 'You must provide a description to create a review.'
                        : undefined
                }
                required
            >
                {(inputProps) => (
                    <TextArea
                        dataTestId='request-review-description'
                        value={description}
                        {...inputProps}
                        onChange={(e) => {
                            setDescription(e.target.value)
                            setDescriptionError(false)
                        }}
                    />
                )}
            </InputWrapper>

            <InputWrapper id='request-reviewers-list' labelText='Reviewers'>
                {(inputProps) => (
                    <Select
                        {...inputProps}
                        data-test-id='request-reviewers-select'
                        options={getReviewersList(Authenticator.getDefaultUserName())}
                        defaultValue={[]}
                        isMulti={true}
                        onChange={setReviewers}
                        placeholder='Select reviewers'
                        getTriggerText={(numberOfSelectedItems: number) =>
                            numberOfSelectedItems === 1
                                ? '1 reviewer selected'
                                : `${numberOfSelectedItems} reviewers selected`
                        }
                        listMaxHeight='20vh'
                    />
                )}
            </InputWrapper>
        </>
    )
}
