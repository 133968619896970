import { BucketsAndCupsGroupHandler } from 'src/services/EntityServices/ActivityUpdateHandlers/BucketsAndCupsGroupHandler'
import { MUPPExamHandler } from 'src/services/EntityServices/ActivityUpdateHandlers/MUPPExamHandler'
import { RandomSelectionGroupHandler } from 'src/services/EntityServices/ActivityUpdateHandlers/RandomSelectionGroupHandler'
import { ContextBoxEntityService } from 'src/services/EntityServices/ContextBoxEntityService'
import { InstructionalContentEntityService } from 'src/services/EntityServices/InstructionalContentEntityService'
import { AudioCheckHandler } from 'src/services/EntityServices/ItemUpdateHandlers/AudioCheckHandler'
import { AudioEvaluationHandler } from 'src/services/EntityServices/ItemUpdateHandlers/AudioEvaluationHandler'
import { ChatHandler } from 'src/services/EntityServices/ItemUpdateHandlers/ChatHandler'
import { CompletionCodeHandler } from 'src/services/EntityServices/ItemUpdateHandlers/CompletionCodeHandler'
import { ContentHandler } from 'src/services/EntityServices/ItemUpdateHandlers/ContentHandler'
import { EmailContentHandler } from 'src/services/EntityServices/ItemUpdateHandlers/EmailContentHandler'
import { FlyoutInstructionalContentHandler } from 'src/services/EntityServices/ItemUpdateHandlers/FlyoutInstructionalContentHandler'
import { InformationImagesHandler } from 'src/services/EntityServices/ItemUpdateHandlers/InformationImagesHandler'
import { LikertGroupHandler } from 'src/services/EntityServices/ItemUpdateHandlers/LikertGroupHandler'
import { MediaStimulusHandler } from 'src/services/EntityServices/ItemUpdateHandlers/MediaStimulusHandler'
import { MultipleChoiceHandler } from 'src/services/EntityServices/ItemUpdateHandlers/MultipleChoiceHandler'
import { RatingHandler } from 'src/services/EntityServices/ItemUpdateHandlers/RatingHandler'
import { SingleSelectCheckboxHandler } from 'src/services/EntityServices/ItemUpdateHandlers/SingleSelectCheckboxHandler'
import { TableHandler } from 'src/services/EntityServices/ItemUpdateHandlers/TableHandler'
import { VirtualMeetingHandler } from 'src/services/EntityServices/ItemUpdateHandlers/VirtualMeetingHandler'
import { WritingExerciseHandler } from 'src/services/EntityServices/ItemUpdateHandlers/WritingExerciseHandler'
import { WrittenResponseHandler } from 'src/services/EntityServices/ItemUpdateHandlers/WrittenResponseHandler'
import { ModuleGroupEntityService } from 'src/services/EntityServices/ModuleGroupEntityService'
import { ValidationErrorEntityService } from 'src/services/EntityServices/ValidationErrorEntityService'
import { ItemGroupHandler } from './ActivityUpdateHandlers/ItemGroupHandler'
import { FreetextHandler } from './ItemUpdateHandlers/FreeTextHandler'
import { RankingResponseTableHandler } from './ItemUpdateHandlers/RankingResponseTableHandler'
import { ActivityEntityService } from './ActivityEntityService'
import { ItemEntityService } from './ItemEntityService'
import { ModuleEntityService } from './ModuleEntityService'

export function initializeStoreServices() {
    const services = [
        ModuleEntityService,
        ActivityEntityService,
        ItemEntityService,
        ValidationErrorEntityService,
        InstructionalContentEntityService,
        ContextBoxEntityService,
        ModuleGroupEntityService,
    ]

    services.forEach((s) => {
        s.init()
    })

    const activityUpdateHandlers = [
        ItemGroupHandler,
        RandomSelectionGroupHandler,
        BucketsAndCupsGroupHandler,
        MUPPExamHandler,
        TableHandler,
    ]

    activityUpdateHandlers.forEach((h) => {
        h.init()
    })

    const itemUpdateHandlers = [
        FreetextHandler,
        LikertGroupHandler,
        AudioEvaluationHandler,
        AudioCheckHandler,
        RatingHandler,
        RankingResponseTableHandler,
        MultipleChoiceHandler,
        FlyoutInstructionalContentHandler,
        ContentHandler,
        SingleSelectCheckboxHandler,
        WrittenResponseHandler,
        MediaStimulusHandler,
        CompletionCodeHandler,
        EmailContentHandler,
        ChatHandler,
        VirtualMeetingHandler,
        WritingExerciseHandler,
        InformationImagesHandler,
    ]

    itemUpdateHandlers.forEach((h) => {
        h.init()
    })

    // Expose entity framework to console
    ;(window as never as { _entity: unknown })._entity = {
        services,
        activityUpdateHandlers,
        itemUpdateHandlers,
    }
}
