import React, { useMemo } from 'react'

import { DropdownButton } from 'src/components/DropdownButton'
import { ActivityType } from 'src/models/dto/ActivityDTO'
import { ModuleLayout } from 'src/models/dto/ModuleDTO'
import { focusOnId, pageFocusId } from 'src/pages/module-builder/focus'
import { ActivityEntityService } from 'src/services/EntityServices/ActivityEntityService'
import { ItemGroupHandler } from 'src/services/EntityServices/ActivityUpdateHandlers/ItemGroupHandler'
import { ContextBoxEntityService } from 'src/services/EntityServices/ContextBoxEntityService'
import { ModuleEntityService } from 'src/services/EntityServices/ModuleEntityService'

interface ActivityDropDownProps {
    id?: string
    moduleEntityId: string
    pageIndex: number
    dataTestId: string
    editDisabled?: boolean
}

export const activityDisplayNameMap = new Map<ActivityType, string>([
    [ActivityType.LaunchItemGroup, 'Default page'],
    [ActivityType.LaunchRandomSelection, 'Random selection group'],
    [ActivityType.LaunchBucketsAndCups, 'Buckets and cups group'],
    [ActivityType.LaunchCAT, 'Work styles exam'],
])

export const ActivityDropDown = ({
    id,
    moduleEntityId,
    pageIndex,
    dataTestId,
    editDisabled,
}: ActivityDropDownProps) => {
    const addPageAriaLabel = `Add page in position ${pageIndex + 1}`
    const dropdownValues = useMemo(
        () =>
            Array.from(activityDisplayNameMap.keys())
                .filter((v) => activityDisplayNameMap.has(v))
                .map((activityType) => {
                    const name: string = activityDisplayNameMap.get(activityType)! //eslint-disable-line @typescript-eslint/no-non-null-assertion

                    if (activityType === ActivityType.LaunchCAT) {
                        const alreadyHasMUPP = ModuleEntityService.get(
                            moduleEntityId
                        ).workflowIds.some(
                            (wId) => ActivityEntityService.get(wId).type === ActivityType.LaunchCAT
                        )
                        return {
                            name,
                            disabled: alreadyHasMUPP,
                            dataTestId: `add-${activityType}`,
                            onClick: () => {
                                ModuleEntityService.updateAutoProgressionOn(moduleEntityId, true)
                                const page = ActivityEntityService.create(activityType)
                                ModuleEntityService.addActivity(moduleEntityId, page.id, pageIndex)
                                focusOnId(pageFocusId(pageIndex + 1))
                            },
                        }
                    }

                    return {
                        name,
                        dataTestId: `add-${activityType}`,
                        onClick: () => {
                            const page = ActivityEntityService.create(activityType)
                            if (
                                activityType === ActivityType.LaunchItemGroup &&
                                ModuleEntityService.get(moduleEntityId).layout ===
                                    ModuleLayout.CustomerFacing
                            ) {
                                const contextBoxDTO = ContextBoxEntityService.create()
                                ItemGroupHandler.setContextBoxId(page.id, contextBoxDTO.id)
                            }
                            ModuleEntityService.addActivity(moduleEntityId, page.id, pageIndex)
                            focusOnId(pageFocusId(pageIndex + 1))
                        },
                    }
                }),
        [pageIndex, moduleEntityId]
    )

    return (
        <DropdownButton
            id={id}
            title='Add new page'
            values={dropdownValues}
            ariaLabel={addPageAriaLabel}
            dataTestId={dataTestId}
            disabled={editDisabled}
        />
    )
}
